// src/components/banner/banner.js

import React from "react"
import GraphicsSVG from "../graphicsSVG"
import {
  MDquery,
  UntilSmartphoneWidthQuery,
  FromDesktopWidthQuery,
} from "../../utils/reactResponsive"
import { BannerSection } from "../sections"
import { IndentedRow, FullRow, BannerHeadlineRow } from "../rows"
import { HeadlineH1 } from "../headline"

const classesFigCaption ="block text-smSM md:text-smMD lg:text-smLG"

const Banner = ({ banner }) => (
  <BannerSection>
    <BannerHeadlineRow>
      <HeadlineH1 {...banner} />
    </BannerHeadlineRow>

    {/* 2 versions of svg until from 0 to 767 and 768  to end */}
    {/* svg grid on headline */}
    <UntilSmartphoneWidthQuery>
      <FullRow className="pb-4">
        <GraphicsSVG id={`${banner.graphicId}`} title={banner.graphicTitle} />
        <figureCaption className={`pt-4 ${classesFigCaption}`}>
          {banner.captionShort}
        </figureCaption>
      </FullRow>
    </UntilSmartphoneWidthQuery>
    {/* MD size also on grid  headline but smaller icons */}
    <MDquery>
      <FullRow className="pb-4">
        <GraphicsSVG
          id={`${banner.graphicId}_md`}
          title={banner.graphicTitle}
        />
      </FullRow>
      <IndentedRow>
        <figureCaption className={`pt-4 ${classesFigCaption}`}>
          {banner.caption}
        </figureCaption>
      </IndentedRow>
    </MDquery>
    {/* Then smaller grid on paragraph */}
    <FromDesktopWidthQuery>
      <IndentedRow className="pb-6 lg:pb-10">
        <GraphicsSVG
          id={`${banner.graphicId}_md`}
          title={banner.graphicTitle}
        />
        <figureCaption className={`pt-4 ${classesFigCaption}`}>
          {banner.caption}
        </figureCaption>
      </IndentedRow>
    </FromDesktopWidthQuery>

    {/* <IndentedRow className="text-smSM md:text-smMD lg:text-smLG">

    </IndentedRow> */}
  </BannerSection>
)

export default Banner
