// src/pages/produkt-strategie.js

import React from "react"
import data from "../data/yaml/productstrategy_page.yaml"
import HeadMetadata from "../components/headMetadata"
import HeaderBar from "../components/headerBar"
import Banner from "../components/banner"
// import Casestudies from "../components/casestudies"
import Work from "../components/work"
import Service from "../components/service"
import Contact from "../components/contact"
import Footer from "../components/footer"

export default () => {

  return (
    <>
      <HeadMetadata {...data.metadata} />
      <HeaderBar />
      <Banner {...data} />
      <Service {...data} />
      {/* <Casestudies isPlaying={false} currentSlide="0" /> */}
      <Work pageId="strategy" {...data.image} />
      <Contact headline={data.contact.headline}  />
      <Footer />
    </>
  )
}
