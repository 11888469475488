// src/components/work/work.js

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import data from "../../data/yaml/repeating_sections.yaml"
import { failIfNotExist } from "../../utils/helper"
import {
  UntilSmartphoneWidthQuery,
  FromTabletWidthQuery,
} from "../../utils/reactResponsive"
import { DarkSection } from "../sections"
import { IndentedRow } from "../rows"

// TODO menu_links_id has to be a global constant, comes
// comes from gatsby-config, also uses for slideshow on service site
const Image = ({ pageId, alt }) => {
  const dataImage = useStaticQuery(graphql`
    query {
      strategy: file(
        relativePath: {
          eq: "pepe/product-strategy-with-user-experience-pepe-juergens.jpg"
        }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 1000, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      experience: file(
        relativePath: { eq: "pepe/user-experience-design-pepe-juergens.jpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 1000, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      production: file(
        relativePath: { eq: "pepe/agile-production-pepe-juergens.jpg" }
      ) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 1000, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  failIfNotExist(
    dataImage[pageId],
    `in casestudies-section Image: unreachable images data "${pageId}"`
  )

  //
  return <Img fluid={dataImage[pageId].childImageSharp.fluid} alt={alt} />
}

Image.propTypes = {
  pageId: PropTypes.string.isRequired,
}

const classesFigCaption = "px-3 block text-white text-smSM md:text-smMD lg:text-smLG"

const Work = ({pageId, alt, caption}) => {
  const { headline, text } = data.work
  
  return (
    <>
      <DarkSection pbClassName="pb-3">
        <IndentedRow>
          <h3 className=" text-primary-500">{headline}</h3>
          <p>{text}</p>
        </IndentedRow>
      </DarkSection>

      <div className="pb-10 bg-warm-gray-900">
        <figure>
          <div className="max-w-screen-lg mx-auto ">
            {/* responsive layot the image */}
            <UntilSmartphoneWidthQuery>
              <Image pageId={pageId} alt={alt} />
              <IndentedRow className={`pt-4 ${classesFigCaption}`}>
                {caption}
              </IndentedRow>
            </UntilSmartphoneWidthQuery>

            <FromTabletWidthQuery>
              <IndentedRow>
                <Image pageId={pageId} alt={alt} />

                <div className={`pt-4 ${classesFigCaption}`}>{caption}</div>
              </IndentedRow>
            </FromTabletWidthQuery>
          </div>
        </figure>
      </div>
    </>
  )
}

export default Work
