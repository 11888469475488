// src/components/service/service.js

import React from "react"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import { getPage } from "../../utils/helper"
import { BrightSection } from "../sections"
import { ReactIcon } from "../icons"
import { LinkButtonPrimary} from "../buttons"
import { ARROW_RIGHT_ICON } from "../icons"
import {FullRow, IndentedRow} from "../rows"

const ServiceDescription = ({ services }) => {
  const value = {
    color: "#101010",
    size: "24px",
    style: { verticalAlign: "middle" },
  }
  return (
    <>
      {services.map(({ headline, text, iconId }, index) => (
        // TODO same code like index.js for quote description
        // make paragraph from
        // <div key={index} className="w-full pb-4 mx-auto sm:w-10/12 sm:w-8/12 ">
        <div key={index} className="w-full pb-4 mx-auto sm:w-8/12 ">
          <h2 className="relative md:font-semibold">
            <span className="absolute left-0 sm:-left-8 lg:-left-11">
              <ReactIcon id={iconId} contextValue={value} />
            </span>
            <span className="ml-8 sm:ml-0">{headline}</span>
          </h2>
          <p className="pb-4">{text}</p>
        </div>
      ))}
    </>
  )
}

const Service = ({ service }) => {
  const { pages } = useSiteMetadata()
  const { nextPageId, headline, text } = service
  const { labelLong, url } = getPage(nextPageId, pages)

  return (
    <BrightSection className="pt-0 md:pt-0 lg:pt-0">
      <FullRow>
        <h4 className="pb-4 text-primary-500">{headline}</h4>
        <p className="pb-12">{text}</p>
      </FullRow>
      <ServiceDescription {...service} />
      <IndentedRow className="pt-12 pb-4">
        <LinkButtonPrimary
          className=""
          url={url}
          label={labelLong}
          useAsLabelProp="labelLong"
          iconId={ARROW_RIGHT_ICON}
        />
      </IndentedRow>
    </BrightSection>
  )
}

export default Service
